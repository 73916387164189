<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <ReturnAddPopup :idx="pop.idx" v-if="pop.isShowReturn" @close="pop.hideReturn"></ReturnAddPopup>
        <ReturnCancelPopup :idx="pop.idx" v-if="pop.isShowCancel" @close="pop.hideCancel"></ReturnCancelPopup>

        <CarrotTitle title="교재 반납 관리">
            - 반납교재 권수 수정 및 삭제는 ‘요청’ 상태일 때만 가능합니다.<br>
            - 교재 명을 클릭하면 요청상세내용을 보실 수 있습니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">완료/취소일</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker> 
                                    ~ 
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                </td>
                                <th width="180">교재명</th>
                                <td>
                                    <carrot-book-title v-model:bookname.sync="bbs.bookname" v-model="bbs.book" class="w-80per"></carrot-book-title>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">고객사명</th>
                                <td>
                                    <carrot-company v-model="bbs.company" class="w-80per"></carrot-company>
                                </td>
                                <th width="180">요청자</th>
                                <td>
                                    <carrot-team :idx_office="bbs.office" v-model="bbs.team" class="w-40per"></carrot-team>
                                    <carrot-staff :idx_team="bbs.team" orderby="easc" v-model="bbs.staff" class="w-40per ml-10"></carrot-staff>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doInit" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>

                    <div class="mt-40 mb-20">
                        <select @change="bbs.doInit" v-model="bbs.btype" class="w-100px float-left">
                            <option value="">구분 전체</option>
                            <option value="내부">내부</option>
                            <option value="외부">외부</option>
                        </select>
                        <select @change="bbs.doInit" v-model="bbs.state" class="w-130px float-left ml-20">
                            <option value="">반납현황 전체</option>
                            <option value="CANCEL">취소</option>
                            <option value="DONE">완료</option>
                            <option value="REQUEST">요청</option>
                        </select>

                        <button @click="bbs.downExcel" class="btn-default float-left ml-20">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="100">
                            <col width="100">
                            <col width="210">
                            <col width="100">
                            <col width="150">
                            <col width="120">
                            <col width="100">
                            <col width="120">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>구분</th>
                                <th>유/무상</th>
                                <th>교재명</th>
                                <th>수량</th>
                                <th>요청자</th>
                                <th>출고일</th>
                                <th>반납수량<br>(유상/무상)</th>
                                <th>최근 반납일</th>
                                <th>반납현황</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.btype }}</td>
                                <td>{{ irow.is_paid=='Y'?'유상':'무상' }}</td>
                                <td>{{ irow.bookname }}</td>
                                <td>{{ irow.qty }}</td>
                                <td>{{ irow.staff_ename }}({{ irow.staff_kname }})</td>
                                <td>{{ irow.request_date }}</td>
                                <td>{{ irow.cnt_paid }}/{{ irow.cnt_unpaid }}</td>
                                <td>{{ irow.return_date?irow.return_date:'-' }}</td>
                                <td>
                                    <button @click="pop.showReturn(irow.idx)" class="btn-default">반납하기</button>
                                    <button @click="pop.showCancel(irow.idx)" class="btn-default mt-10">반납취소</button>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="10">등록된 반납 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotBookTitle from '@/components/common/CarrotBookTitle.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import ReturnAddPopup from '@/components/popup/ResourceManagement/ReturnAddPopup.vue'
import ReturnCancelPopup from '@/components/popup/ResourceManagement/ReturnCancelPopup.vue'
import { useStore } from 'vuex';


export default {
    layout: 'ResourceManagement',
    components: {
        CarrotDatePicker,
        CarrotBookTitle,
        CarrotCompany,
        CarrotTeam,
        CarrotStaff,
        ReturnAddPopup,
        ReturnCancelPopup
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store  = useStore();

        const pop = reactive({
            isShowReturn : false,
            isShowCancel : false,

            showReturn : (idx) => {
                if( bbs.isAuth == false ){
                    Swal.fire({
                        title : '반납하기',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                pop.idx = idx;
                pop.isShowReturn = true;
            },
            hideReturn : () => {
                pop.isShowReturn = false;
                bbs.doSearch();
            },

            showCancel : (idx) => {
                if( bbs.isAuth == false ){
                    Swal.fire({
                        title : '반납취소',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                pop.idx = idx;
                pop.isShowCancel = true;
            },
            hideCancel : () => {
                pop.isShowCancel = false;
                bbs.doSearch();
            },
        });

        const bbs = reactive({
            isAuth    : false,
            isMounted : false,
            isAll : false,
            selected : [],
            page : 1,
            rows : 50,

            sdate     : "",
            edate     : "",
            bookname  : "",
            book      : 0,
            company   : 0,
            office    : "99999",
            team      : 0,
            staff     : 0,

            state     : "",
            btype     : "",

            list : [], total : 0,

            checkAll : () => {
                if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx);
                    }
                } else {
                    bbs.isAll    = false;
                    bbs.selected = [];
                }
            },

            downExcel : () => {
                if( bbs.isAuth == false ){
                    Swal.fire({
                        title : '엑셀다운',
                        text  : '접근 권한이 없습니다.'
                    });
                    return;
                }

                location.href = "https://intranet.mxm.kr/excel/textbook_return_list"
                            + "?sdate=" + bbs.sdate
                            + "&edate=" + bbs.edate
                            + "&book="  + bbs.book
                            + "&company=" + bbs.company
                            + "&team="  + bbs.team
                            + "&staff=" + bbs.staff
                            + "&state=" + bbs.state
                            + "&btype=" + bbs.btype;
            },

            doDone : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '출고완료',
                        text  : '완료할 교재를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '출고완료',
                    text  : '총 ' + bbs.selected.length + '건의 교재 출고를 완료하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/doneBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("완료하였습니다.");
                                bbs.doInit();
                            } else if ( res.data.err == 200 ){
                                let msg = [];
                                let canceled = res.data.canceled;
                                for(let i=0; i<bbs.list.length; i++){
                                    let irow = bbs.list[i];
                                    if( canceled.indexOf(irow.idx) >= 0 ){
                                        msg.push( '[' + irow.title + ']' );
                                    }
                                }
                                msg.join('<br>');
                                Swal.fire({
                                    title : '출고완료',
                                    text  : msg + ' 출고 요청이 재고 부족으로 출고할 수 없습니다.'
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            doCancel : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '출고취소',
                        text  : '취소할 교재를 선택하세요.'
                    });
                    return;
                }

                Swal.fire({
                    title : '출고취소',
                    text  : '총 ' + bbs.selected.length + '건의 교재 출고를 취소하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            selected : bbs.selected
                        };
                        axios.post("/rest/resourceManagement/cancelBookRental", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("취소하였습니다.");
                                bbs.doInit();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        })
                    }
                });
            },

            setStorage : () => {
                sessionStorage.setItem('TextbookRentalList', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    book    : bbs.book,
                    company : bbs.company,
                    team    : bbs.team,
                    staff   : bbs.staff,
                    state   : bbs.state,
                    btype   : bbs.btype,
                }));
            },

            showRental : (i) => {
                let o = bbs.list[i];

                bbs.setStorage();

                router.push({
                    name : 'ResourceManagement-TextbookRentalView-idx',
                    params : {
                        idx : o.idx
                    }
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isAll = false;
                bbs.selected = [];
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate   : bbs.sdate,
                    edate   : bbs.edate,
                    book    : bbs.book,
                    company : bbs.company,
                    team    : bbs.team,
                    staff   : bbs.staff,
                    state   : bbs.state,
                    btype   : bbs.btype,
                };
                axios.get('/rest/resourceManagement/getBookReturnList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAll = false;
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },


        });


        onMounted(() => {
            // Mounted
            if( store.state.idx_office == 38 ) {
                bbs.isAuth = true;
            }

            bbs.isMounted = true;

            let ss = sessionStorage.getItem('TextbookRentalList');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.page ) bbs.page = ss.page;
                if( ss.rows ) bbs.rows = ss.rows;
                if( ss.sdate     ) bbs.sdate     = ss.sdate;
                if( ss.edate     ) bbs.edate     = ss.edate;
                if( ss.bookname  ) bbs.bookname  = ss.bookname;
                if( ss.title     ) bbs.title     = ss.title;
                if( ss.team      ) bbs.team      = ss.team;
                if( ss.staff     ) bbs.staff     = ss.staff;
                if( ss.state     ) bbs.state     = ss.state;
                if( ss.btype     ) bbs.btype     = ss.btype;
            }

            bbs.doSearch();
        });

        return {pop,bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>