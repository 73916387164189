<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">반납취소</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view mt-5" v-for="(irow, i) in pop.books" :key="i">
                    <colgroup>
                        <col width="80">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>교재명</th>
                            <td>{{ irow.bookname }}</td>
                        </tr>
                        <tr>
                            <th>수량</th>
                            <td>
                                <input type="text" v-model="irow.cnt_cancel" @keydown="pop.calcNumber(i, 'cnt_cancel')" @keyup="pop.calcNumber(i, 'cnt_cancel')" class="w-100px">
                                <span class="ml-5">권</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 txt-center">
                <button @click="pop.doSubmit" class="btn-default dp-inblock">반납취소</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
// import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    name: 'ReturnCancelPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        }
    },
    setup(props, {emit}) {
        // const router = useRouter();
        const toast = useToast();

        const pop = reactive({
            idx   : 0,
            info  : {},
            cnt_cancel : 0,

            calcNumber : (i, tg) => {
                let o = pop.books[i];
                let v = o[tg];
                v = v.toString().replace(/[^0-9]/gi, '');
                v = v>0?Number(v):0;
                pop.books[i][tg] = (v>o.qty?o.qty:v).toString();
            },

            doSubmit : () => {
                let params = {
                    idx : pop.idx,
                    books : pop.books
                };

                axios.post('/rest/resourceManagement/setBookReturnCancel', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("반납 취소 완료!");
                        emit('close');
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx
                };
                axios.get('/rest/resourceManagement/getBookRentalInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                        pop.books = pop.info.books;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx   = props.idx;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>